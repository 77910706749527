import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons';
import { t } from 'i18next';
import { useParams } from 'react-router';
import useToast from 'src/hooks/useToast';
import { useSelector } from 'src/store/Store';
import DeleteAlert from 'src/components/shared/DeleteAlert';

import './TournamentTeams.css';

import { useDeleteTeamMutation, useLazyGetTeamsQuery } from 'src/services/tournaments';
import AddEditTeamForm from './components/AddEditTeamForm';
import { Team, TeamStatus } from 'src/services/tournaments/getTournamentTeams';
import useLang from 'src/hooks/useLang';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import { isDateInPast } from './helpers/isDateInPast';
import BlankCard from 'src/components/shared/BlankCard';
import getUserImage from 'src/utils/getUserImage';
import { formatPlayersArray } from './helpers/formatPlayersArray';
import CategorySelection from './components/CategorySelection';

const isWidget = window.location.pathname.includes('/widget');

const TournamentTeams = ({ isLoading }: { isLoading: boolean }) => {
  const { id } = useParams();

  const { toastPromise } = useToast();
  const { getTranslatedTitle } = useLang();

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined);

  const tournamentDetails = useSelector((state) => state.tournamentReducer.selectedTournament);

  const [deleteTeamReq] = useDeleteTeamMutation();
  const [getTeams, getTeamsRes] = useLazyGetTeamsQuery();

  useEffect(() => {
    if (!tournamentDetails) return;
    setSelectedCategory(tournamentDetails?.categories?.[0]?.id);
  }, [tournamentDetails]);

  useEffect(() => {
    if (!selectedCategory) return;
    loadTeams();
  }, [selectedCategory]);

  const loadTeams = () => {
    if (!tournamentDetails) return;
    getTeams({
      tournamentId: Number(id),
      categoryId: Number(selectedCategory),
    });
  };

  const deleteTeam = (teamId: number) => {
    toastPromise({
      request: () => {
        return deleteTeamReq({
          tournamentId: Number(id),
          teamId: Number(teamId),
        });
      },
      onSuccess: (result: any) => {
        if ('error' in result) {
          throw result.error;
        }

        loadTeams();

        return t('TournamentTeams.teamDeletedSuccessfully');
      },
      onError: (err) => {
        if (err.data && err.data.errors) {
          const errorMessages = Object.values(err.data.errors).flat().join('. ');

          return errorMessages;
        }

        if (err?.data?.message) {
          return err?.data?.message;
        }

        return t('TournamentTeams.errorOccurred');
      },
    });
  };

  const HeaderToolbar = () => {
    return (
      <Box display={'flex'} flexDirection={'row'} py={1.5} alignItems={'center'}>
        <Box sx={{ flex: '1 1 100%' }}>
          {isLoading ? (
            <Skeleton sx={{ width: 150 }} variant="text" />
          ) : (
            <Typography variant="h6">{getTranslatedTitle(tournamentDetails, 'name')}</Typography>
          )}
        </Box>
        {isLoading ? (
          <Skeleton sx={{ width: 250 }} variant="text" />
        ) : (
          <>
            <Tooltip title={t('TournamentTeams.category')}>
              <CustomSelect
                id="category_id"
                fullWidth
                onChange={(e: any) => setSelectedCategory(e.target.value)}
                value={selectedCategory}
                sx={{ flex: '0.5 0.5 18.5%' }}
              >
                {tournamentDetails?.categories?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {getTranslatedTitle(option, 'name')}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Tooltip>

            <CategorySelection />
          </>
        )}
      </Box>
    );
  };

  const rows = getTeamsRes?.data?.data || [];

  function Row(props: { row: Team }) {
    const { row } = props;

    return (
      <Grid item xs={12} key={row.id}>
        <BlankCard className="hoverCard">
          <CardContent>
            <Stack direction={'row'} gap={2} justifyContent={'center'}>
              {formatPlayersArray(row.team_players)?.map((player, index) => (
                <>
                  <Stack width={'50%'} key={index} direction={'column'} gap={2} alignItems="center">
                    <Avatar
                      alt="Remy Sharp"
                      src={getUserImage(player.id)}
                      sx={{ width: '80px', height: '80px' }}
                    />
                    <Box textAlign={'center'} height={60}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}
                        fontWeight="bold"
                      >
                        {player?.name}
                      </Typography>
                      <Typography variant="caption">{player.phone}</Typography>
                    </Box>
                  </Stack>
                  {index === 0 && <Divider orientation="vertical" flexItem />}
                </>
              ))}
            </Stack>
          </CardContent>
          <Divider />
          <Box
            height={40}
            sx={{
              backgroundColor: row.status === TeamStatus.DELETED ? 'error.main' : 'grey.100',
              borderRadius: 0,
            }}
          >
            {row.status === TeamStatus.DELETED ? (
              <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                <Typography variant="h6" color="white">
                  Deleted
                </Typography>
              </Stack>
            ) : (
              <Stack justifyContent={'center'} direction="row" spacing={1}>
                {!isWidget && (
                  <Tooltip title={t('Coaches.edit')}>
                    <IconButton
                      size="small"
                      color="info"
                      onClick={() => {
                        setSelectedTeam(row);
                        setIsAddFormOpen(true);
                      }}
                    >
                      <IconEdit width={20} />
                    </IconButton>
                  </Tooltip>
                )}
                {!isWidget && (
                  <Tooltip title={t('Coaches.delete')}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedTeam(row);
                        setDeleteAlertOpen(true);
                      }}
                      color="error"
                    >
                      <IconTrash width={20} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
        </BlankCard>
      </Grid>
    );
  }

  if (isLoading || getTeamsRes?.isLoading || getTeamsRes?.isFetching)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
        <CircularProgress color="secondary" sx={{ m: 5 }} />
      </Box>
    );

  return (
    <>
      <Box flexGrow={1}>
        {!isDateInPast(tournamentDetails?.registration_deadline) && !isWidget && (
          <>
            <Stack my={2} direction="row-reverse" spacing={2}>
              <Button
                onClick={() => {
                  setIsAddFormOpen(true);
                }}
                variant="contained"
                color="primary"
                startIcon={<IconPlus width={18} />}
                sx={{ flex: '0.15 0.15 2%' }}
              >
                {t('TournamentTeams.addTeam')}
              </Button>
            </Stack>
          </>
        )}
        <HeaderToolbar />
        <Divider />
      </Box>
      <Grid container spacing={2} mt={1}>
        {rows
          ?.filter((row) => row.status !== TeamStatus.DELETED)
          ?.map((row) => (
            <Grid item xs={4} key={row.id}>
              <Row row={row} />
            </Grid>
          ))}
      </Grid>

      <AddEditTeamForm
        open={isAddFormOpen}
        onClose={() => {
          setSelectedTeam(undefined);
          setIsAddFormOpen(false);
        }}
        selectedTeam={selectedTeam}
        loadTeams={loadTeams}
        tournamentId={Number(id)}
        categoryId={Number(selectedCategory)}
      />
      <DeleteAlert
        open={deleteAlertOpen}
        handleClose={() => setDeleteAlertOpen(false)}
        title={t('TournamentTeams.deleteTeam')}
        message={t('TournamentTeams.deleteTeamMessage')}
        buttons={[
          {
            text: t('Coaches.cancel'),
            color: 'primary',
            onClick: () => setDeleteAlertOpen(false),
          },
          {
            text: t('Coaches.delete'),
            color: 'error',
            onClick: () => deleteTeam(selectedTeam?.id),
          },
        ]}
      />
    </>
  );
};

export default TournamentTeams;
