import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Tournament } from './getTournaments';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Tournament, AddTournamentBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/edit?_method=PUT`,
      method: 'POST',
      body: body.formData,
      
    }),
  });

export interface AddTournamentBody {
  id: number;
  formData: FormData;
}

export enum TournamentsTypes {
  ROUND_ROBIN = 1,
  DOUBLE_ROUND_ROBIN = 2,
  ROUND_ROBIN_SINGLE_ELIMINATION = 3,
  SINGLE_ELIMINATION = 4,
}

export enum PaymentMethods {
  Cash = '1',
  Tap = '5',
  ApplePay = '11',
}
