import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'src/store/Store';

import useLang from 'src/hooks/useLang';

import { SingleEliminationBracket, SVGViewer } from '@g-loot/react-tournament-brackets';
import useWindowSize from './Hooks/useWindowSize';
import { KnockoutStage } from './mockApiData';
import { convertToSimpleBracket } from './Helpers/convertApiDataToViewData';
import MatchCard from './Components/MatchCard';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import { useLazyGetTournamentKnockoutsQuery } from 'src/services/tournaments';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';

const isWidget = window.location.pathname.includes('/widget');

const TournamentKnockoutTab = ({
  AdditionalTournaments,
}: {
  AdditionalTournaments: () => JSX.Element;
}) => {
  const { id } = useParams();

  const tournament = useSelector((state) => state.tournamentReducer.selectedTournament);
  const tournamentId = tournament?.id;

  const [getTournamentKnockouts, { isLoading, isFetching, data }] =
    useLazyGetTournamentKnockoutsQuery();

  const { getTranslatedTitle } = useLang();

  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [stages, setStages] = useState<KnockoutStage[] | undefined>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const containerOption = useSelector((state) => state.customizer.isLayout);
  const isFull = containerOption === 'full';

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (selectedCategory) {
      const stagesOfCategory = data?.data?.[selectedCategory]?.knockouts?.rounds;
      setStages(stagesOfCategory);
    }
  }, [selectedCategory, data]);

  const getData = async () => {
    const result = await getTournamentKnockouts({ id: Number(id) || tournamentId });
    const responseData = result?.data?.data;
    if (responseData && typeof responseData === 'object') {
      const categoriesData = Object.keys(responseData);
      setCategories(categoriesData);
      if (categoriesData?.length !== 0) {
        setSelectedCategory(categoriesData[0]);
      }
    }
  };

  const renderPicker = () => {
    if (AdditionalTournaments) {
      return <AdditionalTournaments />;
    }

    if (categories?.length > 0) {
      return (
        <CustomSelect
          id="category_id"
          fullWidth
          onChange={(e: any) => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          sx={{ flex: '0.25 0.25 15%', backgroundColor: 'white' }}
        >
          {categories?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
      );
    }

    return <></>;
  };

  const HeaderToolbar = () => (
    <Box display={'flex'} flexDirection={'row'} mt={1} alignItems={'center'}>
      <Box sx={{ flex: '1 1 100%' }}>
        {isLoading ? (
          <Skeleton sx={{ width: 150 }} variant="text" />
        ) : (
          <Typography variant="h6">{getTranslatedTitle(tournament, 'name')}</Typography>
        )}
      </Box>
      {renderPicker()}
    </Box>
  );

  const [width, height] = useWindowSize();
  let finalWidth = Math.max(isFull ? width * 0.81 : width * 0.68, 500);
  let finalHeight = Math.max(isFull ? height * 0.47 : height * 0.5, 500);

  finalHeight = isWidget ? finalHeight * 1.7 : finalHeight;
  finalWidth = isWidget ? finalWidth * 1.5 : finalWidth;

  if (isLoading || isFetching) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
        <CircularProgress color="secondary" sx={{ m: 5 }} />
      </Box>
    );
  }

  return (
    <Box sx={{ overflowX: 'hidden', width: '100%' }}>
      <HeaderToolbar />
      <Divider sx={{ mt: 1 }} />

      {convertToSimpleBracket(stages).length === 0 ? (
        <Typography m={5} textAlign={'center'} variant="h6">
          {t('TournamentStandingScreen.standingEmptyState')}
        </Typography>
      ) : (
        <>
          {/* <Stack
            direction={englishRowJustification}
            sx={{ mt: 2 }}
            overflow={'scroll'}
            mr={rtl ? 4 : 0}
          >
            {stages?.map((stage, index) => (
              <Stack
                width={350}
                flexDirection={englishRowJustification}
                justifyContent={stage?.round_start_date ? 'space-between' : 'center'}
                alignItems={'center'}
                key={index}
                px={6}
              >
                <Typography variant="h6">{t(`TournamentScheduleTab.${stage?.round}`)}</Typography>
                {stage?.round_start_date && (
                  <Typography variant="h6">
                    {moment(stage?.round_start_date).format('DD/MM/YYYY')}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack> */}

          <Box sx={{ overflowX: 'scroll', overflowY: 'scroll' }}>
            <SingleEliminationBracket
              matches={convertToSimpleBracket(stages).reverse()}
              matchComponent={({ match }) => {
                return <MatchCard match={match} />;
              }}
              svgWrapper={({ children, ...props }) => {
                return (
                  <SVGViewer width={finalWidth} height={finalHeight} {...props}>
                    {children}
                  </SVGViewer>
                );
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TournamentKnockoutTab;
