import React, { useEffect, useMemo, useState } from 'react';
import { CardMedia, Grid } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';

import { t } from 'i18next';
import { useLazyGetTournamentDetailsQuery } from 'src/services/tournaments';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'src/store/Store';
import { setSelectedTournament } from 'src/store/apps/tournament/TournamentSlice';
import Details from './components/Details';
import BlankCard from 'src/components/shared/BlankCard';
import profilecover from 'src/assets/images/backgrounds/profilebg.jpg';
import TournamentTabs from './components/TournamentTabs';
import TournamentOrganizers from 'src/views/pages/tournamentOrganizers/TournamentOrganizers';
import TournamentTeams from 'src/views/pages/tournamentTeams/TournamentTeams';
import Spinner from 'src/views/spinner/Spinner';
import TournamentStandings from '../Standings/TournamentStandings';
import Schedule from '../Schedule/Schedule';
import Knockouts from '../Knockouts/Knockouts';
import ApprovalQueue from './components/ApprovalQueue';

export enum TournamentTabsEnum {
  DETAILS = 'details',
  ORGANIZERS = 'organizers',
  TEAMS = 'teams',
  SCHEDULE = 'schedule',
  STANDINGS = 'standings',
  KNOCKOUTS = 'knockouts',
}

const isWidget = window.location.pathname.includes('/widget');

const TournamentProfile = ({
  AdditionalTournaments,
}: {
  AdditionalTournaments: () => JSX.Element;
}) => {
  //widget url will contain the tab as param as tab=schedule, use react router to get the tab from the url
  const { tab: tabFromUrl } = useParams();

  const [tab, setTab] = useState<TournamentTabsEnum>(
    isWidget ? (tabFromUrl as TournamentTabsEnum) : TournamentTabsEnum.DETAILS,
  );
  const [isApprovalQueueOpen, setIsApprovalQueueOpen] = useState(false);

  const [getTournamentDetails, { isLoading, isFetching }] = useLazyGetTournamentDetailsQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedTournament = useSelector((state) => state.tournamentReducer.selectedTournament);

  const { id, stadiumId } = useParams();

  const stadium = useSelector((state) => state.configReducer.selectedStadium);

  useEffect(() => {
    loadTournamentDetails();
  }, [id]);

  useEffect(() => {
    //check if there is /widget string in the url
    if (isWidget) {
    }
  }, []);

  const loadTournamentDetails = () => {
    getTournamentDetails({ id: Number(id) }).then((res) => {
      dispatch(setSelectedTournament(res.data.data));
    });
  };

  useEffect(() => {
    if (stadium?.id !== Number(stadiumId) && !isWidget) {
      navigate(-1);
    }
  }, [stadium?.id, stadiumId]);

  const renderTabs = useMemo(() => {
    switch (tab) {
      case TournamentTabsEnum.DETAILS:
        return <Details setIsApprovalQueueOpen={setIsApprovalQueueOpen} />;
      case TournamentTabsEnum.ORGANIZERS:
        return <TournamentOrganizers isLoading={isLoading} />;
      case TournamentTabsEnum.TEAMS:
        return <TournamentTeams isLoading={isLoading} />;
      case TournamentTabsEnum.SCHEDULE:
        return <Schedule isLoading={isLoading} AdditionalTournaments={AdditionalTournaments} />;
      case TournamentTabsEnum.STANDINGS:
        return (
          <TournamentStandings
            isLoading={isLoading}
            AdditionalTournaments={AdditionalTournaments}
          />
        );
      case TournamentTabsEnum.KNOCKOUTS:
        return <Knockouts AdditionalTournaments={AdditionalTournaments} />;
      default:
        return <Details setIsApprovalQueueOpen={setIsApprovalQueueOpen} />;
    }
  }, [tab, selectedTournament]);

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  return (
    <PageContainer
      title={t('TournamentProfile.title')}
      description={t('TournamentProfile.description')}
    >
      <Grid container>
        <Grid item sm={12}>
          <BlankCard>
            {!isWidget ? (
              <CardMedia
                component="img"
                image={selectedTournament?.tournament_image || profilecover}
                alt={profilecover}
                width="100%"
                height={400}
              />
            ) : null}

            {!isWidget ? <TournamentTabs tab={tab} setTab={setTab} /> : null}
          </BlankCard>
        </Grid>
        {renderTabs}
      </Grid>
      {!isWidget && (
        <ApprovalQueue
          open={isApprovalQueueOpen}
          onClose={() => setIsApprovalQueueOpen(false)}
          loadTournamentDetails={loadTournamentDetails}
        />
      )}
    </PageContainer>
  );
};

export default TournamentProfile;
