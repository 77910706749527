import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { IconInfoSquare, IconTournament } from '@tabler/icons';
import { t } from 'i18next';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupsIcon from '@mui/icons-material/Groups';
import { TournamentTabsEnum } from '../TournamentProfile';
import { BackupTable, Schedule } from '@mui/icons-material';
import { TournamentsTypes } from 'src/services/tournaments/addTournament';
import { useSelector } from 'src/store/Store';

const isWidget = window.location.pathname.includes('/widget');

type Props = {
  tab: TournamentTabsEnum;
  setTab: React.Dispatch<React.SetStateAction<TournamentTabsEnum>>;
};

const TournamentTabs = (props: Props) => {
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    props.setTab(newValue as TournamentTabsEnum);
  };

  const tournament = useSelector((state) => state.tournamentReducer.selectedTournament);

  const hasKnockOut =
    tournament?.tournament_type?.id === TournamentsTypes.ROUND_ROBIN_SINGLE_ELIMINATION ||
    tournament?.tournament_type?.id === TournamentsTypes.SINGLE_ELIMINATION;

  interface TournamentTabTypes {
    label: string;
    icon: any;
    key: TournamentTabsEnum;
  }

  const hasKnockOutTabs = hasKnockOut
    ? [
        {
          label: t('TournamentProfile.knockouts'),
          icon: <IconTournament width={20} />,
          key: TournamentTabsEnum.KNOCKOUTS,
        },
      ]
    : [];

  const hasOrganizers = isWidget
    ? []
    : [
        {
          label: t('TournamentProfile.details'),
          icon: <IconInfoSquare size="20" />,
          key: TournamentTabsEnum.DETAILS,
        },
        {
          label: t('TournamentProfile.organizers'),
          icon: <AdminPanelSettingsIcon width={20} />,
          key: TournamentTabsEnum.ORGANIZERS,
        },
        {
          label: t('TournamentProfile.teams'),
          icon: <GroupsIcon width={20} />,
          key: TournamentTabsEnum.TEAMS,
        },
      ];

  const TournamentTabs: TournamentTabTypes[] = [
    ...hasOrganizers,
    {
      label: t('TournamentProfile.schedule'),
      icon: <Schedule width={20} />,
      key: TournamentTabsEnum.SCHEDULE,
    },
    {
      label: t('TournamentProfile.standings'),
      icon: <BackupTable width={20} />,
      key: TournamentTabsEnum.STANDINGS,
    },
    ...hasKnockOutTabs,
  ];

  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.grey[100], borderRadius: 0 }}>
      <Box
        justifyContent={'end'}
        display="flex"
        sx={{ overflow: 'auto', width: { xs: '333px', sm: 'auto' } }}
      >
        <Tabs
          value={props.tab}
          onChange={handleChange}
          aria-label="scrollable prevent tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {TournamentTabs.map((tab) => {
            return (
              <Tab
                iconPosition="start"
                label={tab.label}
                sx={{ minHeight: '50px' }}
                icon={tab.icon}
                key={tab.key}
                value={tab.key}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
};

export default TournamentTabs;
