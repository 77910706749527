import React, { useMemo } from 'react';
import { Typography, Grid, Avatar, Box, Button, Divider } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { ScheduleMatch } from 'src/services/tournaments/getTournamentSchedule';
import { formatArray } from '../helpers/formatScoresArray';
import useMoment from 'src/views/pages/schedule/hooks/useMoment';
import { t } from 'i18next';
import './MatchItem.css'; // Import the CSS file

const isWidget = window.location.pathname.includes('/widget');

const PadelMatchCard: React.FC<{ row: ScheduleMatch; onEdit: () => void }> = ({ row, onEdit }) => {
  const [moment] = useMoment();

  const team1 = useMemo(() => {
    return {
      player1: {
        name: row.teams_1_players?.[0]?.name || '-',
        image: row.teams_1_players?.[0]?.image_url,
      },
      player2: {
        name: row.teams_1_players?.[1]?.name || '-',
        image: row.teams_1_players?.[1]?.image_url,
      },
      sets: formatArray(row.teams_results?.map((result) => result?.team_1?.toString()) || []),
      winner: row.winner_team && row.winner_team === row?.tournament_team_1_id,
    };
  }, [row]);

  const team2 = useMemo(() => {
    return {
      player1: {
        name: row.teams_2_players?.[0]?.name || '-',
        image: row.teams_2_players?.[0]?.image_url,
      },
      player2: {
        name: row.teams_2_players?.[1]?.name || '-',
        image: row.teams_2_players?.[1]?.image_url,
      },
      sets: formatArray(row.teams_results?.map((result) => result?.team_2?.toString()) || []),
      winner: row.winner_team && row.winner_team === row?.tournament_team_2_id,
    };
  }, [row]);

  return (
    <Grid className="match-card">
      <Box className="header-box">
        <Typography variant="body1" className="header-title">
          {row?.pitch_name} {row?.position ? (`/${row?.position}`) : ""}
        </Typography>

        {!isWidget && (
          <Button onClick={onEdit} variant="contained" color="secondary">
            {t('EditMatch.editDetails')}
          </Button>
        )}
      </Box>

      <Grid>
        <Box mb={0} paddingRight={0} paddingLeft={1}>
          <Box mt={0} display="flex" alignItems="center" justifyContent="space-between">
            <div>
              <Box display="flex" alignItems="center">
                <Avatar src={team1.player1.image} alt={team1.player1.name} />
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {team1.player1.name}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginTop={1}>
                <Avatar src={team1.player2.image} alt={team1.player2.name} />
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {team1.player2.name}
                </Typography>
                {team1.winner && <EmojiEventsIcon sx={{ ml: 1, color: '#FFD700' }} />}
              </Box>
            </div>

            <Box className="sets-container">
              {team1?.sets?.map((set, index) => (
                <Box key={index} className="set-box">
                  <Typography variant="h6">{set}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 0 }} />

        <Box mb={0} paddingRight={0} paddingLeft={1}>
          <Box mt={0} display="flex" alignItems="center" justifyContent="space-between">
            <div>
              <Box display="flex" alignItems="center">
                <Avatar src={team1.player1.image} alt={team1.player1.name} />
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {team2.player1.name}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginTop={1}>
                <Avatar src={team1.player2.image} alt={team1.player2.name} />
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {team2.player2.name}
                </Typography>
                {team2.winner && <EmojiEventsIcon sx={{ ml: 1, color: '#FFD700' }} />}
              </Box>
            </div>

            <Box className="sets-container">
              {team2?.sets?.map((set, index) => (
                <Box key={index} className="set-box">
                  <Typography variant="h6">{set}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Box className="footer-box">
        <Typography variant="body1" className="footer-time">
          {row?.match_time
            ? moment(`${row.match_date} ${row?.match_time}`, 'YYYY-MM-DD HH:mm:ss').format('LLL')
            : '-'}
        </Typography>

        <Typography variant="body1" className="footer-status">
          {row?.is_live ? t('TournamentScheduleTab.liveMatch') : '-'}
        </Typography>
      </Box>
    </Grid>
  );
};

export default PadelMatchCard;
