import { MenuItem, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router-dom';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import TournamentProfile from 'src/views/pages/tournaments/components/TournamentInfo/TournamentProfile';
import Logo from 'src/assets/images/widget/logo.png';
import AppStore from 'src/assets/images/widget/appstore.png';
import GooglePlay from 'src/assets/images/widget/googleplay.png';

const TournamentWidget = () => {
  const { additionalTournaments, additionalTournamentsNames, id, token, tab } = useParams();

  const additionalTournamentsNamesArray =
    additionalTournamentsNames === 'NA' ? [] : additionalTournamentsNames.split(',');

  const additionalTournamentsIds =
    additionalTournaments === 'NA' ? [] : additionalTournaments.split(',');

  const AdditionalTournaments = () => {
    if (additionalTournamentsIds.length === 0 || additionalTournamentsNamesArray.length === 0)
      return null;

    return (
      <Box m={0} flexGrow={1}>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography variant="h6">Classes</Typography>
          <CustomSelect
            id="category_id"
            // fullWidth
            onChange={(e: any) => {
              // setSelectedCategory(e.target.value)}
              window.open(
                `/tournaments/widget/${e.target.value}/${tab}/${additionalTournaments}/${additionalTournamentsNames}/${token}`,
                '_self',
              );
            }}
            value={id}
            sx={{
              marginRight: 1,
            }}
          >
            {additionalTournamentsNamesArray?.map((option, index) => (
              <MenuItem key={index} value={additionalTournamentsIds[index]}>
                {option}
              </MenuItem>
            ))}
          </CustomSelect>
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Box
        alignItems={'center'}
        bgcolor={'#09112E'}
        width={'100%'}
        height={'84px'}
        borderRadius={0}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-around'}
      >
        <Box component={'img'} src={Logo} width={'106px'} height={'51px'} />
        <Typography color={'white'} variant="h6" letterSpacing={0.5}>
          {'Stay updated on tournaments – Download our app now!'.toUpperCase()}
        </Typography>
        <Box display={'flex'} flexDirection={'row'}>
          <Box
            onClick={() =>
              window.open(
                'https://apps.apple.com/eg/app/malaeb-%D9%85%D9%84%D8%A7%D8%B9%D8%A8/id1094201670',
                '_blank',
              )
            }
            component={'img'}
            src={AppStore}
            width={175}
            height={'52px'}
            sx={{ cursor: 'pointer' }}
          />
          <Box
            onClick={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.malaebapp.app',
                '_blank',
              )
            }
            ml={3}
            component={'img'}
            src={GooglePlay}
            width={175}
            height={'52px'}
            sx={{ cursor: 'pointer' }}
          />
        </Box>
      </Box>
      <Box p={5}>
        <TournamentProfile AdditionalTournaments={AdditionalTournaments} />
      </Box>
    </Box>
  );
};

export default TournamentWidget;
