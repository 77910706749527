import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Match } from '../types';
import { t } from 'i18next';
import trophy from 'src/assets/images/tournaments/Trophy.png';

import './matchCard.css';

const scoreProps = {
  bgcolor: 'white',
  item: true,
  xs: 0.9,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 0.5,
  mx: 0.1,
  maxHeight: 23,
  mt: 1.5,
};

const MatchCard = ({ match }: { match: Match }) => {
  const team1Result = match?.participants?.[0]?.resultText?.split('-');
  const team2Result = match?.participants?.[1]?.resultText?.split('-');

  return (
    <Box
      sx={{
        maxWidth: 550,
        backgroundColor: '#DFEEFF',
      }}
      height={100}
    >
      <Grid container>
        <Grid bgcolor={'#000E41'} item xs={1} height={50} display="flex" justifyContent="center">
          <Typography
            fontSize={8}
            color={'white'}
            variant="h6"
            my={1}
            sx={{
              textOrientation: 'sideways',
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
            }}
          >
            {t('TournamentScheduleTab.teamA')}
          </Typography>
        </Grid>
        <Grid item xs={7} display="flex" flexDirection="column" justifyContent="center">
          <Typography color={'#4392F1'} sx={{ ml: '5px' }} variant="h6" fontSize={10}>
            {match?.participants?.[0]?.name || '-'}
          </Typography>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
          {match?.participants?.[0]?.isWinner && match?.participants?.[0]?.id && (
            <img className="match-card-winner-icon" src={trophy} alt="winner" />
          )}
        </Grid>
        <Grid {...scoreProps}>
          <Typography fontSize={10}>{team1Result?.[0] || '-'}</Typography>
        </Grid>
        <Grid {...scoreProps}>
          <Typography fontSize={10}>{team1Result?.[1] || '-'}</Typography>
        </Grid>
        <Grid {...scoreProps}>
          <Typography fontSize={10}>{team1Result?.[2] || '-'}</Typography>
        </Grid>

        <Grid bgcolor={'#000E41'} item xs={1} height={50} display="flex" justifyContent="center">
          <Typography
            fontSize={8}
            color={'white'}
            variant="h6"
            my={1}
            sx={{
              textOrientation: 'sideways',
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
            }}
          >
            {t('TournamentScheduleTab.teamB')}
          </Typography>
        </Grid>
        <Grid item xs={7} display="flex" flexDirection="column" justifyContent="center">
          <Typography color={'#4392F1'} sx={{ ml: '5px' }} variant="h6" fontSize={10}>
            {match?.participants?.[1]?.name || '-'}
          </Typography>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
          {match?.participants?.[1]?.isWinner && match?.participants?.[1]?.id && (
            <img className="match-card-winner-icon" src={trophy} alt="winner" />
          )}
        </Grid>
        <Grid {...scoreProps}>
          <Typography fontSize={10}>{team2Result?.[0] || '-'}</Typography>
        </Grid>
        <Grid {...scoreProps}>
          <Typography fontSize={10}>{team2Result?.[1] || '-'}</Typography>
        </Grid>
        <Grid {...scoreProps}>
          <Typography fontSize={10}>{team2Result?.[2] || '-'}</Typography>
        </Grid>
      </Grid>
      <div className="match-card-line" />
    </Box>
  );
};

export default MatchCard;
