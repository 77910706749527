import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router';
import { useLazyGetTournamentStandingsQuery } from 'src/services/tournaments';
import { t } from 'i18next';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import { useSelector } from 'src/store/Store';
import useLang from 'src/hooks/useLang';
import AppCard from 'src/components/shared/AppCard';
import { categorize, formatGroupTable, formatRounds, formatStanding } from './Helpers/Standings';
import {
  Round,
  Standing,
  Standings,
  TournamentMatches,
} from 'src/services/tournaments/getTournamentStandings';
import { TournamentsTypes } from 'src/services/tournaments/addTournament';
import useMoment from 'src/views/pages/schedule/hooks/useMoment';

const TournamentStandings = ({
  isLoading,
  AdditionalTournaments,
}: {
  isLoading: boolean;
  AdditionalTournaments: () => JSX.Element;
}) => {
  const { id } = useParams();

  const { getTranslatedTitle } = useLang();
  const [moment] = useMoment();

  const [getTournamentStandings, getTournamentStandingsRes] = useLazyGetTournamentStandingsQuery();

  const tournamentDetails = useSelector((state) => state.tournamentReducer.selectedTournament);

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [categories, setCategories] = useState<string[]>([]);
  const [standing, setStandings] = useState<Standing>({});
  const [data, setData] = useState<Standings[]>([]);

  useEffect(() => {
    getTournamentStandings({ id: Number(id) });
  }, [id]);

  useEffect(() => {
    if (getTournamentStandingsRes.data) {
      setStandings(getTournamentStandingsRes.data.data);
      const categoriesOutput = categorize(getTournamentStandingsRes.data.data);
      setCategories(categoriesOutput);
      setSelectedCategory(categoriesOutput[0]);
    }
  }, [getTournamentStandingsRes.data]);

  useEffect(() => {
    if (selectedCategory) {
      const formattedStanding = formatStanding(standing[selectedCategory]);
      setData(formattedStanding);
    }
  }, [selectedCategory]);

  const renderPicker = () => {
    if (AdditionalTournaments) {
      return <AdditionalTournaments />;
    }
    if (categories?.length > 0) {
      return (
        <CustomSelect
          id="category_id"
          fullWidth
          onChange={(e: any) => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          sx={{ flex: '0.25 0.25 15%', backgroundColor: 'white' }}
        >
          {categories?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
      );
    }

    return <></>;
  };

  const HeaderToolbar = () => (
    <Box display={'flex'} flexDirection={'row'} py={1} alignItems={'center'}>
      <Box sx={{ flex: '1 1 100%' }}>
        {isLoading ? (
          <Skeleton sx={{ width: 150 }} variant="text" />
        ) : (
          <Typography variant="h6">{getTranslatedTitle(tournamentDetails, 'name')}</Typography>
        )}
      </Box>
      {isLoading ||
      getTournamentStandingsRes?.isLoading ||
      getTournamentStandingsRes?.isFetching ? (
        <Skeleton sx={{ width: 250 }} variant="text" />
      ) : (
        renderPicker()
      )}
    </Box>
  );

  const renderGroup = (group: Standings, index: number) => {
    const groupName = t('TournamentStandingScreen.group', { value: index + 1 });
    const groupData = formatGroupTable(group);

    return (
      <Box key={index}>
        <Typography mt={3} mb={2} variant="h6">
          {groupName}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={'10%'}>
                  <Typography variant="h6">{t('TournamentStandingScreen.rank')}</Typography>
                </TableCell>
                <TableCell width={'40%'}>
                  <Typography variant="h6">{t('TournamentStandingScreen.team')}</Typography>
                </TableCell>
                <TableCell width={'10%'}>
                  <Typography variant="h6">{t('TournamentStandingScreen.p')}</Typography>
                </TableCell>
                <TableCell width={'10%'}>
                  <Typography color={'green'} variant="h6">
                    {t('TournamentStandingScreen.w')}
                  </Typography>
                </TableCell>
                <TableCell width={'10%'}>
                  <Typography color={'red'} variant="h6">
                    {t('TournamentStandingScreen.l')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupData.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {rowData.map((cellData, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Typography
                        color={cellIndex === 3 ? 'green' : cellIndex === 4 ? 'red' : 'inherit'}
                        variant="subtitle1"
                      >
                        {cellData}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderRound = (round: Round) => {
    const lastMatch = round.round === 'Finals';
    const matchHaveResult = Boolean(round.winner_team);
    const team1Win = round.winner_team === round.tournament_team_1_id;
    const matchHavePlayers = Boolean(round.team_1_number && round.team_2_number);

    return (
      <Box
        ml={2}
        key={round.id}
        sx={{ mb: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2, p: 2 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2">
            {round?.match_date ? moment(round.match_date).format('DD/MM/YYYY') : ''}
          </Typography>
          <Typography variant="body2">{round.round}</Typography>
        </Box>
        <Box>
          {!matchHavePlayers && (
            <Typography variant="body2">{t('TournamentStandingScreen.tbd')}</Typography>
          )}
          {matchHavePlayers && (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: matchHaveResult ? (team1Win ? 'bold' : 'normal') : 'normal',
                  color: matchHaveResult ? (team1Win ? 'green' : 'inherit') : 'inherit',
                  textDecoration: matchHaveResult ? (team1Win ? 'none' : 'line-through') : 'none',
                }}
              >
                {`${t('TournamentStandingScreen.team')} ${round.team_1_number}: ${
                  round.team_1_player_names
                }`}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: matchHaveResult ? (!team1Win ? 'bold' : 'normal') : 'normal',
                  mt: 1,
                  color: matchHaveResult ? (!team1Win ? 'green' : 'inherit') : 'inherit',
                  textDecoration: matchHaveResult ? (!team1Win ? 'none' : 'line-through') : 'none',
                }}
              >
                {`${t('TournamentStandingScreen.team')} ${round.team_2_number}: ${
                  round.team_2_player_names
                }`}
              </Typography>
              {lastMatch && matchHaveResult && (
                <Typography variant="body2" sx={{ mt: 1, color: 'green' }}>
                  {t('TournamentStandingScreen.winner')}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const renderRounds = (group: TournamentMatches) => {
    const rounds = formatRounds(group);

    return rounds.map(renderRound);
  };

  const foundData = data && Array.isArray(data) && data.length !== 0;
  const isSingleElimination =
    tournamentDetails?.tournament_type.id === TournamentsTypes.SINGLE_ELIMINATION;

  if (isLoading || getTournamentStandingsRes?.isLoading || getTournamentStandingsRes?.isFetching)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
        <CircularProgress color="secondary" sx={{ m: 5 }} />
      </Box>
    );

  return (
    <Box m={1} flexGrow={1}>
      <HeaderToolbar />
      <Divider sx={{ ml: 2 }} />
      <Box mt={3}>
        {foundData ? (
          <>
            {!isSingleElimination
              ? (Array.isArray(data?.[0]) ? data?.[0] : [])?.map(renderGroup)
              : null}

            {data?.length > 1 && isSingleElimination && Array.isArray(data?.[1])
              ? (Array.isArray(data?.[1]) ? data?.[1] : [])?.map(renderRounds)
              : null}
          </>
        ) : (
          <Typography m={5} textAlign={'center'} variant="h6">
            {t('TournamentStandingScreen.standingEmptyState')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TournamentStandings;
