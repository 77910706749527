import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentScheduleReponse, { id: number }>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/list-schedule`,
      method: 'Get',
    }),
  });

export interface CustomMatch extends ScheduleMatch {
  position: string
}

export type TournamentScheduleReponse = {
  data: {
    [index: string]: {
      category: {
        id: number;
        name: string;
        name_ar: string;
      };
      tournament_match_groups: {
        tournament_matches: ScheduleMatch[];
        tournament_custom_matches: CustomMatch[]
      }[];
    };
  };
};

export type ScheduleMatch = {
  id: number;
  pitch_name: string;
  match_date: string;
  match_time: string;
  is_live: boolean;
  round: number;
  tournament_match_group_id: number;
  tournament_team_1_id: number;
  tournament_team_2_id: number;
  team_1_points: number;
  team_2_points: number;
  winner_team: number;
  teams_1_players: SchedulePlayer[];
  teams_2_players: SchedulePlayer[];
  teams_results: {
    team_1: number;
    team_2: number;
    winner_team: 'team_1' | 'team_2';
  }[];
  tournament_team_1_live_score: number;
  tournament_team_2_live_score: number;
  dateTime: number;
  stream_url?: string;
  match_meta_info: {
    first_serve_team_id: number;
    id: number;
    is_first_serve_played: number;
    serving_player_id: number;
    serving_position: number;
    serving_team_id: number;
    set_end_score: number;
  };
  position?: string
};

export type SchedulePlayer = {
  id: number;
  phone: number;
  first_name: string;
  last_name: string;
  birthday: string;
  gender: number;
  bio: string;
  position: number;
  rating: number;
  is_player: number;
  image_url?: string;
  name: string;
  skill_level_ar: string;
  skill_level_en: string;
  is_has_profile?: boolean;
  team: number;
  team_player_position: number;
  user_id: number;
  skill_level_id: number;
};
