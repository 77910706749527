import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Tournament } from './getTournaments';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Tournament, AddTournamentBody>({
    query: (body) => ({
      url: 'v4/pitch-owner/tournaments',
      method: 'POST',
      body: body.formData,
    }),
  });

export interface AddTournamentBody {
  formData: FormData;
}

export enum TournamentsTypes {
  ROUND_ROBIN = 1,
  DOUBLE_ROUND_ROBIN = 2,
  ROUND_ROBIN_SINGLE_ELIMINATION = 3,
  SINGLE_ELIMINATION = 4,
}

export enum PaymentMethods {
  Cash = '1',
  Tap = '5',
  ApplePay = '11',
}

export const paymentMethodsArr = [
  { value: PaymentMethods.Cash, label: 'Cash' },
  { value: PaymentMethods.Tap, label: 'Credit Card' },
  { value: PaymentMethods.ApplePay, label: 'Apple Pay' },
];
